import { useMutation, gql, useQuery } from "@apollo/client";
import { Typography, CircularProgress, Grid, Button, Chip, Box, Link, List, ListItem, ListItemText, Card, CardContent, CardHeader } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Doc, getIconForDocument, DocType, DocStatus, DocInput, TransientDocType } from "documents";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { longDateFormat, dateWithTimeFormat, dateFormat } from "application/formats";
import { RouteNames } from "application/routes";
import UpsertDocumentDialog from "./UpsertDocumentDialog";
import DeleteDocumentDialog from "./DeleteDocumentDialog";
import useCurrentUser from "../users/useCurrentUser";
import { Favourite, userCanEditDocument, userCanDeleteDocument, userCanReplaceFile, Permissions } from "../users";
import { useNotifications } from "../notifications";
import ReplaceFileDialog from "./ReplaceFileDialog";
import ConfirmReportBrokenLinkDialog from "./ConfirmReportBrokenLinkDialog";
import clsx from "clsx";
import qs from "query-string";
import DOMPurify, { sanitize } from "dompurify";
import { useDocumentDownloader } from "./DocumentDownloadProvider";
import { makeStyles, createStyles } from '@mui/styles';
import { GetDocumentForDocumentScreen, GetFutureVersions } from "./queries";

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      main: {
         flexGrow: 1,
         "& > *": {
            marginBottom: theme.spacing(2),
         },
         "& label": {
            color: theme.palette.text.secondary,
            display: "block",
         },
      },
      actions: {
         width: "10rem",
         flexShrink: 0,
      },
      title: {
         marginBottom: theme.spacing(2),
         display: "flex",
         alignItems: "flex-start",
         "& :not(:last-child)": {
            marginRight: theme.spacing(2),
         },
         "& svg": {
            position: "relative",
            top: "0.15em",
         },
      },
      subtitle: {
         fontSize: theme.typography.subtitle1.fontSize,
         color: theme.palette.text.secondary,
      },
      chipList: {
         marginTop: theme.spacing(2),
         marginBottom: theme.spacing(2),
         "& .MuiChip-root:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
      },
      buttonGrid: {
         height: "100%",
         display: "flex",
         flexDirection: "column",
         "& > :not(:last-child)": {
            marginBottom: theme.spacing(2),
         },
      },
      deleteButton: {
         marginTop: "auto",
         color: theme.palette.error.main,
      },
      htmlComment: {
         padding: 0,
      },
   }),
);

export const DocumentScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const navigate = useNavigate();
   const location = useLocation();
   const { user, userHasPermission } = useCurrentUser();
   const { openDocumentInWindow } = useDocumentDownloader();
   const notifications = useNotifications();

   const [editing, setEditing] = useState(false);
   const [deleting, setDeleting] = useState(false);
   const [replacingFile, setReplacingFile] = useState(false);
   const [confirmingReportBrokenLink, setConfirmingReportBrokenLink] = useState(false);

   const routingParams = useParams<{ id: string }>();
   const queryStringParams = new URLSearchParams(location.search);
   const orgIdVersion = queryStringParams.get("orgIdVersion");

   const getDocumentQuery = useQuery<
      { document: Doc },
      { id?: number; catalogueNumber: string | null; wythVersion: boolean; orgIdVersion: number | null }
   >(GetDocumentForDocumentScreen,
      {
         variables: {
            id: routingParams.id ? parseInt(routingParams.id) : undefined,
            catalogueNumber: queryStringParams.get("catalogueNumber") ?? "",
            wythVersion: queryStringParams.get("wythVersion")?.toLowerCase() === "true",
            orgIdVersion: orgIdVersion !== null ? parseInt(orgIdVersion) : null,
         },
      },
   );

   const getFutureVersionsQuery = useQuery<{ documentVersions: Doc[] },
      { id?: number; catalogueNumber: string | null; wythVersion: boolean; orgIdVersion: number | null }>(GetFutureVersions, {
         variables: {
            id: routingParams.id ? parseInt(routingParams.id) : undefined,
            catalogueNumber: queryStringParams.get("catalogueNumber") ?? "",
            wythVersion: queryStringParams.get("wythVersion")?.toLowerCase() === "true",
            orgIdVersion: orgIdVersion !== null ? parseInt(orgIdVersion) : null,
         },
      });

   const document = !getDocumentQuery.loading && getDocumentQuery.data ? getDocumentQuery.data.document : null;
   const futureVersions = !getFutureVersionsQuery.loading && getFutureVersionsQuery.data ? getFutureVersionsQuery.data.documentVersions : [];

   const [favouriteMutate] = useMutation<{ favourite: { create: Favourite } }, { documentId: number }>(
      gql`
         mutation AddToFavourites($documentId: Int!) {
            favourite {
               create(documentId: $documentId) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetCurrentUser"],
      },
   );

   const [unfavouriteMutate] = useMutation<{ favourite: { delete: Favourite } }, { documentId: number; userId: number }>(
      gql`
         mutation Unfavourite($documentId: Int!, $userId: Int!) {
            favourite {
               delete(documentId: $documentId, userId: $userId) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetCurrentUser"],
      },
   );

   async function favourite() {
      const result = await favouriteMutate({
         variables: {
            documentId: document!.id,
         },
      });

      if (result.data?.favourite?.create?.id) {
         notifications.success("Added favourite.");
      }
   }

   async function unfavourite() {
      const result = await unfavouriteMutate({
         variables: {
            documentId: document!.id,
            userId: user!.id,
         },
      });

      if (result.data?.favourite?.delete.id) {
         notifications.success("Unfavourited document.");
      }
   }

   function getInheritedTypeHierarchy(type: DocType): string {
      if (!type.parent || type.parent.name === TransientDocType) return type.name;
      else return `${type.name} > ${getInheritedTypeHierarchy(type.parent)}`;
   }

   function handleDocumentUpdated(updatedDocument?: DocInput) {
      setEditing(false);

      const queryStringCatalogueNumber = queryStringParams.get("catalogueNumber");
      if (updatedDocument && queryStringCatalogueNumber && queryStringCatalogueNumber !== updatedDocument.catalogueNumber) {
         navigate({
            ...location,
            search: qs.stringify({ ...qs.parse(location.search), catalogueNumber: updatedDocument.catalogueNumber }),
         }, { replace: true });
      }
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>{`${document ? document.identifier : "Document"} - Concentra Partner Portal`}</title>
         </Helmet>
         {document === null ? (
            <CircularProgress />
         ) : (
            <>
               <Grid container spacing={3} wrap="nowrap">
                  <Grid item className={classes.main}>
                     <Typography variant="h4" className={classes.title}>
                        {getIconForDocument(document, "1em")}
                        <div>
                           <div>{document.identifier}</div>
                           {document.fileName && document.fileName !== document.identifier && (
                              <div className={classes.subtitle}>{document.fileName}</div>
                           )}
                           {document.url && (
                              <Link color="inherit" href={sanitize(document.url)} target="_blank" rel="noopener" className={classes.subtitle}>
                                 {document.url}
                              </Link>
                           )}
                        </div>
                     </Typography>

                     <Typography variant="h5">{moment.utc(document.date).format(longDateFormat)}</Typography>

                     {document.catalogueNumber && (
                        <div>
                           <label>Catalogue Number</label>
                           <Typography variant="body1">{document.catalogueNumber}</Typography>
                        </div>
                     )}

                     {document.version && (
                        <div>
                           <label>Version</label>
                           <Typography variant="body1">{document.version}</Typography>
                        </div>
                     )}

                     {document.jurisdictions.length > 0 && (
                        <div>
                           <label>Jurisdiction{document.jurisdictions.length > 1 ? "s" : ""}</label>
                           <Typography variant="body1">{document.jurisdictions.map((j) => j.name).join(", ")}</Typography>
                        </div>
                     )}

                     {document.status !== DocStatus.NA && (
                        <div>
                           <label>Status</label>
                           <Typography variant="body1">{document.status}</Typography>
                        </div>
                     )}

                     {user.isWyth && (
                        <div>
                           <label>Organization</label>
                           <Typography variant="body1">{document.organization?.name ?? "None"}</Typography>
                        </div>
                     )}

                     <div>
                        <label>Product{document.products.length > 1 ? "s" : ""}</label>
                        <div>
                           {document.products.length > 0 ? (
                              document.products.map((p) => (
                                 <Box key={p.id} mb={1}>
                                    <Typography variant="body1">{p.name}</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                       {`${p.program.name}`}
                                    </Typography>
                                 </Box>
                              ))
                           ) : (
                              <Typography variant="body1">None</Typography>
                           )}
                        </div>
                     </div>
                     {document.plans.length > 0 && (
                        <div>
                           <label>Plan type{document.plans.length > 1 ? "s" : ""}</label>
                           <Typography variant="body1">{document.plans.map((p) => p.name).join(", ")}</Typography>
                        </div>
                     )}
                     <div>
                        <label>Document type</label>
                        <Typography variant="body1">{document.type.name}</Typography>
                        {document.type.parent && (
                           <Typography variant="body2" color="textSecondary">
                              {getInheritedTypeHierarchy(document.type.parent)}
                           </Typography>
                        )}
                     </div>

                     <div className={classes.chipList}>
                        <label>Tags</label>
                        <div>
                           {document.tags.length > 0 ? (
                              document.tags.map((t) => <Chip key={t.id} size="small" label={t.value} />)
                           ) : (
                              <Typography variant="body1">None</Typography>
                           )}
                        </div>
                     </div>

                     <div>
                        <label>Comments</label>
                        {document.comments && document.comments.length > 0 ? (
                           <div
                              className={clsx("ql-editor", classes.htmlComment)}
                              dangerouslySetInnerHTML={{
                                 __html: DOMPurify.sanitize(document.comments),
                              }}
                           />
                        ) : (
                           <div>
                              <Typography variant="body1">None</Typography>
                           </div>
                        )}
                     </div>

                     <Typography variant="body1" color="textSecondary">
                        {`Posted by
                           ${document.uploadedBy ? `${document.uploadedBy.name} (${document.uploadedBy.organization.name})` : "unknown"} at
                           ${moment(document.uploadDate).format(dateWithTimeFormat)}`}
                     </Typography>
                  </Grid>
                  {futureVersions.length > 0 && userHasPermission(Permissions.PostEditDeleteFutureDocuments) && (
                     <Grid item>
                        <Card>
                           <CardHeader title="Future Document Versions" />
                           <List>
                              {futureVersions.map(fv =>
                                 <ListItem button component="a" href={`documents/${fv.id}`} target="_blank">
                                    <ListItemText
                                       primary={`${fv.fileName} - Live on: ${moment.utc(fv.date).format(dateFormat)}`}
                                       secondary={`Posted by ${fv.uploadedBy ? `${fv.uploadedBy.name} (${fv.uploadedBy.organization.name})` : "unknown"} at
                                                ${moment(fv.uploadDate).format(dateWithTimeFormat)}`}
                                    />
                                 </ListItem>
                              )}
                           </List>
                        </Card>
                     </Grid>)}

                  <Grid item className={classes.actions}>
                     <div className={classes.buttonGrid}>
                        <Button variant="contained" color="secondary" onClick={() => openDocumentInWindow(document)}>
                           {document.fileName ? "Download" : "Open in new tab"}
                        </Button>
                        {userCanEditDocument(user, document) && (
                           <Button variant="outlined" onClick={() => setEditing(true)}>
                              Edit...
                           </Button>
                        )}
                        {document.fileName && userCanReplaceFile(user, document) && (
                           <Button variant="outlined" onClick={() => user.isWyth && userHasPermission(Permissions.PostEditDeleteFutureDocuments) ? setEditing(true) : setReplacingFile(true)}>
                              Replace file...
                           </Button>
                        )}
                        {document.url && (
                           <Button variant="outlined" onClick={() => setConfirmingReportBrokenLink(true)}>
                              Report broken link...
                           </Button>
                        )}
                        {user.favourites.some((f) => f.document.id === document.id) ? (
                           <Button variant="outlined" onClick={unfavourite}>
                              Unfavourite
                           </Button>
                        ) : (
                           <Button variant="outlined" onClick={favourite}>
                              Add to favourites
                           </Button>
                        )}
                        {userCanDeleteDocument(user, document) && (
                           <Button className={classes.deleteButton} variant="outlined" onClick={() => setDeleting(true)}>
                              Delete
                           </Button>
                        )}
                     </div>
                  </Grid>
               </Grid>
               <UpsertDocumentDialog
                  open={editing}
                  documentIdToEdit={document.id}
                  handleClose={handleDocumentUpdated}
                  skipRefetchDocumentScreenQueryAfterEdit={true}
               />
               <DeleteDocumentDialog
                  documentToDelete={deleting ? document : null}
                  handleClose={() => setDeleting(false)}
                  callback={() => navigate(RouteNames.Home)}
               />
               {document.fileName && (
                  <ReplaceFileDialog
                     open={replacingFile}
                     documentIdToEdit={document.id}
                     currentFileName={document.fileName}
                     handleClose={(fileReplaced) => {
                        setReplacingFile(false);
                        if (fileReplaced) getDocumentQuery.refetch();
                     }}
                  />
               )}

               {confirmingReportBrokenLink && (
                  <ConfirmReportBrokenLinkDialog
                     documentWithBrokenLink={document}
                     handleClose={() => {
                        setConfirmingReportBrokenLink(false);
                     }}
                  />
               )}
            </>
         )}
      </div>
   );
};
