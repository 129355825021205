import React, { useState, useMemo } from "react";
import { Theme, TextField, Grid, Typography, FormControlLabel, Checkbox, FormHelperText, Box, Card, CardContent, CardHeader } from "@mui/material";
import { Helmet } from "react-helmet";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import useCurrentUser from "../users/useCurrentUser";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Formik, Form as FormikForm, Field as FormikField, FieldProps, FieldArray, FormikHelpers } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import SpinnerButton from "SpinnerButton";
import * as Yup from 'yup';
import { gql, useQuery } from "@apollo/client";
import { Lender, Rate, User } from ".";
import { RateTerm } from "./models";
import { useNotifications } from "notifications";
import { useAuthentication } from "auth/AuthenticationProvider";
import { useApi } from "api";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      deleteContainer: {
         marginTop: theme.spacing(5)
      },
      deleteButton: {
         color: theme.palette.error.main,
      },
      rates: {
         border: '1px solid #adadad',
         borderRadius: '4px',
         marginTop: theme.spacing(1)
      }
   }),
);

interface FormValues {
   rate: Rate | null;
   user: User | null;
   cashableRate: RateTerm;
   creditUnion: Lender | null;
   effectiveDate: Date | null;
   nonRedeemable: RateTerm[];
}

export const ExternalRateChangeForm: React.FunctionComponent = () => {
   const classes = useStyles();
   const { user } = useCurrentUser();
   const notifications = useNotifications();
   const [isCashable, setIsCashable] = useState(false);
   const { getAccessToken } = useAuthentication();
   const { postAsync } = useApi();

   const userIsWyth = user ? user.isWyth : false;

   const initialFormValues: FormValues = {
      rate: {
         rateID: 3,
         rateName: "Non-Redeemable",
      }
      ,
      user: null,
      cashableRate: { term: "1", value: "" },
      effectiveDate: null,
      creditUnion: null,
      nonRedeemable: [
         { term: "1", value: "" },
         { term: "1.5", value: "" },
         { term: "2", value: "" },
         { term: "2.5", value: "" },
         { term: "3", value: "" },
         { term: "3.5", value: "" },
         { term: "4", value: "" },
         { term: "4.5", value: "" },
         { term: "5", value: "" }
      ]
   };

   const creditUnionQuery = useQuery<{ creditUnions: Lender[] }, { userIsWyth: boolean }>(
      gql`
         query GetCreditUnions {
            creditUnions{
               lenderID
               lenderName
               lenderCode
            }
         }
      `,
      {
         variables: {
            userIsWyth: user.isWyth,
         },
      },
   );
   const creditUnions = useMemo(() => _.orderBy(creditUnionQuery.data?.creditUnions, (cu) => cu.lenderName) ?? [], [creditUnionQuery.data]);

   const rateQuery = useQuery<{ rates: Rate[] }, { userIsWyth: boolean, rateIds: number[] }>(
      gql`
         query GetRates($rateIds: [Int]){
            rates(rateIds: $rateIds){
               rateID
               rateName
            }
         }
      `,
      {
         variables: {
            rateIds: [1, 3],
            userIsWyth: user.isWyth
         },
      }
   );
   const rates = useMemo(() => _.orderBy(rateQuery.data?.rates, (r) => r.rateName) ?? [], [rateQuery.data]);

   function onChangeCreditUnion(newCreditUnion: Lender | null, fieldProps: FieldProps) {
      fieldProps.form.setFieldValue("creditUnion", newCreditUnion);
      onChangeIsCashableRate(false, fieldProps);
   }

   function onChangeIsCashableRate(isCashableRate: boolean, fieldProps: FieldProps) {
      isCashableRate ? fieldProps.form.setFieldValue("nonRedeemable", initialFormValues.nonRedeemable) :
         fieldProps.form.setFieldValue("cashableRate", initialFormValues.cashableRate);

      fieldProps.form.setFieldValue("rate",
         isCashableRate ? rates.find(r => r.rateID === 1) : rates.find(r => r.rateID === 3));
      setIsCashable(isCashableRate);
   }

   const validationSchema = Yup.object().shape({
      user: Yup.object().shape({
         username: Yup.string().nullable().required('This Field is Required'),
         userEmail: Yup.string().email("Enter a valid email").required('This Field is Required'),
      }),
      effectiveDate: Yup.date().nullable().required('This Field is Required').min(new Date(new Date().setHours(0, 0, 0, 0)), "Date must be the present or a future date"),
      creditUnion: Yup.object().shape({
         lenderId: Yup.number(),
         lenderName: Yup.string(),
         lenderCode: Yup.string()
      }).required('This Field is Required').typeError('This field is Required'),
      rate: Yup.object().shape({
         rateId: Yup.number(),
         rateName: Yup.string()
      }).notRequired(),
      cashableRate: Yup.object().when("rate", {
         is: (rate: Rate) => { return rate !== null && rate.rateID === 1 },
         then: (s) => Yup.object().shape({
            value: Yup.number().required("This field is required").typeError('This field must be a valid number')
               .min(0.0001, "Value must be above 0.001").max(9.999, "value must be below 9.999")
               .test("maxPrecision", "number field must have 3 digits after the decimal or less", (value) => value ? /^\d+(\.{1}\d{0,3})?$/.test(value.toString()) : true)
         })
      }),
      nonRedeemable: Yup.array().when("rate", {
         is: (rate: Rate) => { return rate !== null && rate.rateID !== 1 },
         then: (s) => Yup.array().of(
            Yup.object().shape({
               value: Yup.number().typeError('This field must be a valid number')
                  .min(0.0001, "Value must be above 0.001").max(9.999, "value must be below 9.999")
                  .test("maxPrecision", "number field must have 3 digits after the decimal or less", (value) => value ? /^\d+(\.{1}\d{0,3})?$/.test(value.toString()) : true)
            })
         ).test("oneValue", "There must be at least one rate change.", (nr) => {
            return nr?.some(n => (n.value ?? 0) > 0) ?? false;
         })
      })

   });

   async function submitExternalRateChange(values: FormValues, actions: FormikHelpers<any>) {
      try {
         const accessToken = await getAccessToken();
         if (!!accessToken) {
            await postAsync(
               "/api/capitalmarket/new/external",
               JSON.stringify({
                  rate: values.rate,
                  user: values.user,
                  effectiveDate: values.effectiveDate,
                  creditUnion: values.creditUnion,
                  cashableRate: {
                     term: values.cashableRate.term,
                     value: values.cashableRate.value == "" ? null : Number(values.cashableRate.value)
                  },
                  nonRedeemable: values.nonRedeemable.map(nr => ({
                     term: nr.term,
                     value: nr.value == "" ? null : Number(nr.value)
                  }))
               }),
               accessToken
            );
         }
         actions.resetForm();
         notifications.success("Rate posted.");
      } catch (error: any) {
         actions.setSubmitting(false);
         notifications.error(error.message ?? error);
      }
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>Credit Union Nominee Rates - Concentra Partner Portal</title>
         </Helmet>
         {
            creditUnions.length === 0 ?
               (<Typography>You are not a part of a credit union with access to this form.</Typography>) :
               (<Formik initialValues={{ ...initialFormValues, user: { username: user.name, userEmail: user.email }, creditUnion: userIsWyth ? initialFormValues.creditUnion : creditUnions[0] }}
                  validationSchema={validationSchema} onSubmit={(values, actions) => { submitExternalRateChange(values, actions) }} enableReinitialize>
                  {(formikProps) => {
                     return (
                        <Grid container direction="column" spacing={3}>
                           <Grid item container justifyContent="space-between">
                              <Grid item>
                                 <Typography variant="h4">Credit Union Nominee Rates</Typography>
                              </Grid>
                           </Grid>
                           <FormikForm>
                              <Box mb={0} p={2} pb={0} >
                                 <Grid item container spacing={3}>
                                    <Grid item xs={12} sm={6} lg={4} xl={4}>
                                       <FormikField
                                          component={FmuiTextField}
                                          name="user"
                                          variant="outlined"
                                          value={formikProps.values.user.username}
                                          label="User"
                                          fullWidth
                                          disabled
                                       />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4} xl={4}>
                                       <FormikField>
                                          {(fieldProps: FieldProps<String>) => (
                                             <Autocomplete
                                                inputValue={formikProps.values.creditUnion?.lenderName ?? ""}
                                                disabled={fieldProps.form.isSubmitting || !userIsWyth}
                                                autoComplete
                                                options={_.orderBy(creditUnions, (cu) => cu.lenderName) ?? []}
                                                getOptionLabel={(cu) => cu.lenderName}
                                                onChange={(e, newValue) => onChangeCreditUnion(newValue, fieldProps)}
                                                renderInput={(params) =>
                                                   <TextField {...params} variant="outlined" label="Credit Union Name"
                                                      error={formikProps.touched.creditUnion && formikProps.errors.creditUnion !== undefined}
                                                      helperText={formikProps.touched.creditUnion ? formikProps.errors.creditUnion : undefined}
                                                   />
                                                }
                                             />
                                          )}
                                       </FormikField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4} xl={4}>


                                       <FormikField name="effectiveDate">
                                          {(fieldProps: FieldProps<Date>) => (
                                             <DatePicker
                                                inputFormat="yyyy-MM-dd"
                                                label="Effective Date"
                                                disabled={fieldProps.form.isSubmitting}
                                                value={fieldProps.field.value}
                                                disablePast={true}
                                                renderInput={(params) =>
                                                   <TextField  {...params}
                                                      fullWidth
                                                      error={
                                                         fieldProps.form.touched.effectiveDate && fieldProps.form.errors.effectiveDate !== undefined
                                                      }
                                                      helperText={
                                                         fieldProps.form.touched.effectiveDate ? fieldProps.form.errors.effectiveDate : undefined
                                                      }
                                                   />}
                                                onChange={(newDate: Date | null) =>
                                                   fieldProps.form.setFieldValue("effectiveDate", newDate)
                                                }


                                             />)}
                                       </FormikField>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box p={2} >
                                 <Grid item container spacing={3}>
                                    <Grid item xs={12} sm={6} lg={4} xl={4} hidden={formikProps.values.creditUnion?.lenderName !== "Innovation Credit Union"}>
                                       <FormikField name="isCashable">
                                          {(fieldProps: FieldProps<boolean>) => {
                                             return (
                                                <FormControlLabel
                                                   control={<Checkbox checked={isCashable} onChange={(e, newValue) => onChangeIsCashableRate(newValue, fieldProps)}
                                                      disabled={fieldProps.form.isSubmitting} />}
                                                   label="Is this a cashable rate?"
                                                />
                                             )
                                          }}
                                       </FormikField>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={0} p={2} pt={0} >
                                 <Card>
                                    <CardHeader title="Please only enter rates which require a change" />
                                    <CardContent>

                                       {isCashable ? (
                                          <>
                                             <Box mb={2} pl={3} >
                                                <Grid item container spacing={3}>
                                                   <Grid xs={12} sm={12} lg={12} xl={12}>
                                                      <FormikField
                                                         component={FmuiTextField}
                                                         name="cashableRate.value"
                                                         variant="outlined"
                                                         value={formikProps.values.cashableRate.value}
                                                         label={`${formikProps.values.cashableRate.term} Year Cashable Rate %`}
                                                         fullWidth
                                                         disabled={formikProps.isSubmitting}
                                                         error={
                                                            formikProps.touched.cashableRate && formikProps.errors.cashableRate !== undefined
                                                         }
                                                         helperText={
                                                            formikProps.touched.cashableRate ? formikProps.errors.cashableRate : undefined
                                                         }
                                                      />
                                                   </Grid>
                                                </Grid>
                                             </Box>
                                          </>
                                       ) : (
                                          <>
                                             <Box mb={2} >
                                                <Grid item container spacing={3}>
                                                   <FieldArray name="nonRedeemable">
                                                      {() => (
                                                         <>
                                                            {formikProps.values.nonRedeemable.map((rate, index) => (
                                                               <Grid item xs={12} sm={12} lg={12} xl={12} key={index}>
                                                                  <FormikField
                                                                     component={FmuiTextField}
                                                                     name={`nonRedeemable.${index}.value`}
                                                                     variant="outlined"
                                                                     label={`${rate.term} Year Non-Redeemable %`}
                                                                     fullWidth
                                                                     disabled={formikProps.isSubmitting}
                                                                  />
                                                               </Grid>
                                                            ))}
                                                         </>
                                                      )}
                                                   </FieldArray>
                                                </Grid>
                                             </Box>
                                             <Box mb={1} pl={3} pt={3} >
                                                <Grid item container spacing={3}>
                                                   <FormHelperText className="Mui-error MuiFormHelperText-contained">{formikProps.touched.nonRedeemable && !Array.isArray(formikProps.errors.nonRedeemable) ? formikProps.errors.nonRedeemable : undefined}</FormHelperText>
                                                </Grid>
                                             </Box>
                                          </>
                                       )}
                                    </CardContent>
                                 </Card>
                              </Box>
                              <Box mb={2} p={2} >
                                 <Grid item container spacing={3}>
                                    <Grid item xs={12} sm={6} lg={4} xl={3}>
                                       <SpinnerButton
                                          label="Post"
                                          color="primary"
                                          variant="contained"
                                          inProgress={formikProps.isSubmitting}
                                          onClick={() => {
                                             formikProps.submitForm();
                                          }}
                                       />
                                    </Grid>
                                 </Grid>
                                 <Grid item container spacing={3}>
                                    <Grid item>
                                       <h3>Please contact <a href="mailto:deposits@eqbank.ca">deposits@eqbank.ca</a> for any questions or concerns regarding the form</h3>
                                    </Grid>
                                 </Grid>
                              </Box>
                           </FormikForm>
                        </Grid>
                     )
                  }}
               </Formik>)
         }
      </div>
   )
}
